import ApiAppLang from '@/api/app-langs'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'AppLangsTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Code',
					value: 'code'
				},
				{
					text: 'Title',
					value: 'title'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'code',
						name: 'Code',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'title',
						name: 'Title',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'code',
						name: 'Code',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'title',
						name: 'Title',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				multipart: true
			}
		}
	},
	computed: {
		tableData () {
			return this.state.appLangs
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiAppLang.getAll(this)
		}
	},
	methods: {
		createLang (data) {
			this.form = data
			ApiAppLang.create(this)
		},
		updateLang (data) {
			this.form = data.form
			ApiAppLang.update(this, data.id)
		},
		deleteLang (id) {
			ApiAppLang._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiAppLang.getAll(this)
	}
}
