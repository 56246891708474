import ApiHomeSponsor from '@/api/home-sponsor'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'HomeSponsorTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Link',
					value: 'link'
				},
				{
					text: 'Icon url',
					sortable: false,
					value: 'imageUrl'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'link',
						name: 'Link',
						rules: [
							v => !!v || 'Field is required',
							v => /^(ftp|http|https):\/\/[^ "]+$/.test(v) || this.trans('URL must be a valid'),
						],
						// hint: 'The URL must start with "http://" or "https://"'
					},
					{
						model: 'uploadFile',
						name: 'IconUrl',
						file: true
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'link',
						name: 'Link',
						rules: [
							v => !!v || 'Field is required',
							v => /^(ftp|http|https):\/\/[^ "]+$/.test(v) || this.trans('URL must be a valid'),
						],
						hint: 'The URL must start with "http://" or "https://"'
					},
					{
						model: 'uploadFile',
						name: 'IconUrl',
						file: true
					}
				],
				multipart: true
			}
		}
	},
	computed: {
		tableData () {
			return this.state.homeSponsorList
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiHomeSponsor.getAll(this)
		}
	},
	methods: {
		createMedal (data) {
			this.form = data
			ApiHomeSponsor.create(this)
		},
		updateMedal (data) {
			this.form = data.form
			ApiHomeSponsor.update(this, data.id)
		},
		deleteMedal (id) {
			ApiHomeSponsor._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiHomeSponsor.getAll(this)
	}
}
