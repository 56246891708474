import ApiContactSalesRequest from '@/api/contact-sales-request'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'ContactSalesRequestsTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Name',
					value: 'name'
				},
				{
					text: 'Email',
					value: 'email'
				},
				{
					text: 'Company',
					value: 'company'
				},
				{
					text: 'Website',
					value: 'website'
				},
				{
					text: 'Theme',
					value: 'discuss_theme',
					sortable: false
				},
				{
					text: 'Message',
					value: 'message',
					sortable: false
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'name',
						name: 'Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'company',
						name: 'Company',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'website',
						name: 'Website',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'discuss_theme',
						name: 'Theme',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'message',
						name: 'Message',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'name',
						name: 'Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'company',
						name: 'Company',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'website',
						name: 'Website',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'discuss_theme',
						name: 'Theme',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'message',
						name: 'Message',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.contactSalesRequests
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiContactSalesRequest.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiContactSalesRequest.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiContactSalesRequest.update(this, data.id)
		},
		deleteItem (id) {
			ApiContactSalesRequest._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		},
		showErrors (err) {
			this.$refs.table.showErrors(err)
		}
	},
	created () {
		ApiContactSalesRequest.getAll(this)
	}
}
