import ApiOpenPositions from '@/api/open-position'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'OpenPositionsTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Slug',
					value: 'slug'
				},
				{
					text: 'Title',
					value: 'title'
				},
				{
					text: 'Gender',
					value: 'gender'
				},
				{
					text: 'Location',
					value: 'location'
				},
				{
					text: 'Work arrangement',
					value: 'work_arrangement'
				},
				{
					text: 'Short description',
					value: 'short_description'
				},
				{
					text: 'Description',
					value: 'description'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'slug',
						name: 'Slug',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'title',
						name: 'Title',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'gender',
						name: 'Gender',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'location',
						name: 'Location',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'work_arrangement',
						name: 'Work arrangement',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'short_description',
						name: 'Short description',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'description',
						name: 'Description',
						rules: [
							v => !!v || 'Field is required'
						],
						rich: true
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'slug',
						name: 'Slug',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'title',
						name: 'Title',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'gender',
						name: 'Gender',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'location',
						name: 'Location',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'work_arrangement',
						name: 'Work arrangement',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'short_description',
						name: 'Short description',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'description',
						name: 'Description',
						rules: [
							v => !!v || 'Field is required'
						],
						rich: true
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.openPositions
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiOpenPositions.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiOpenPositions.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiOpenPositions.update(this, data.id)
		},
		deleteItem (id) {
			ApiOpenPositions._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiOpenPositions.getAll(this)
	}
}
