import ApiTestimonial from '@/api/testimonial'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'TestimonialTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Author',
					value: 'author'
				},
				{
					text: 'Position',
					value: 'position'
				},
				{
					text: 'Message',
					value: 'message'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'author',
						name: 'Author',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'position',
						name: 'Position',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'message',
						name: 'Message',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'author',
						name: 'Author',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'position',
						name: 'Position',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'message',
						name: 'Message',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.testimonials
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiTestimonial.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiTestimonial.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiTestimonial.update(this, data.id)
		},
		deleteItem (id) {
			ApiTestimonial._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiTestimonial.getAll(this)
	}
}
