import ApiNewPartnerRequest from '@/api/new-partner-request'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'NewPartnerRequestsTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Name',
					value: 'name'
				},
				{
					text: 'Email',
					value: 'email'
				},
				{
					text: 'Website url',
					value: 'website'
				},
				{
					text: 'Message',
					value: 'message'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'name',
						name: 'Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'website',
						name: 'Website url',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'message',
						name: 'Message',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'name',
						name: 'Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'website',
						name: 'Website url',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'message',
						name: 'Message',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.newPartnerRequests
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiNewPartnerRequest.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiNewPartnerRequest.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiNewPartnerRequest.update(this, data.id)
		},
		deleteItem (id) {
			ApiNewPartnerRequest._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		},
		showErrors (err) {
			this.$refs.table.showErrors(err)
		}
	},
	created () {
		ApiNewPartnerRequest.getAll(this)
	}
}
