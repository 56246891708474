var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"hide-default-footer":!_vm.rows || _vm.rows.length < 10,"no-data-text":"no data yet","search":_vm.search},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"mt-10 display-2"},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('admin_no_available_data'))}})])]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","max-width":"180"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"900px"},on:{"click:outside":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"success","outlined":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('admin_new_one'))}}),_c('v-icon',[_vm._v("mdi-plus-circle-outlined")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-sheet',{staticClass:"headline mt-4 mb-5",attrs:{"width":"100%"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.formTitle)}})]),_c('v-sheet',{attrs:{"width":"100%"}},[(_vm.editIndex === -1)?_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_vm._l((_vm.config.createFields),function(field){return _c('v-col',{key:field.name,attrs:{"cols":"12"}},[(field.file)?_c('v-file-input',{attrs:{"height":"18","outlined":"","requiried":"","clearable":"","name":field.model,"enctype":"multipart/form-data","rules":field.rules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.rich)?_c('vue-editor',{model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.select)?_c('v-select',{attrs:{"height":"18","outlined":"","items":_vm.state[field.items],"item-value":"id","item-text":"name","requiried":"","clearable":"","rules":field.rules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.checkbox)?_c('v-checkbox',{attrs:{"height":"18","outlined":"","requiried":"","clearable":"","rules":field.rules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.date)?_c('div',{staticClass:"d-flex justify-space-between py-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","height":"18","outlined":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}})],1)],1):_c('v-text-field',{attrs:{"height":"18","outlined":"","requiried":"","clearable":"","hint":field.hint,"rules":field.rules,"error-messages":_vm.errors[field.model]},on:{"keydown":function($event){return _vm.clearError(field.model)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}})],1)}),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"success","outlined":"","type":"submit"}},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('add'))}}),_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1)],2)],1):_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_vm._l((_vm.config.updateFields),function(field){return _c('v-col',{key:field.name,attrs:{"cols":"12"}},[(field.file)?_c('v-file-input',{attrs:{"height":"18","outlined":"","clearable":"","name":field.model,"enctype":"multipart/form-data","rules":field.rules},on:{"change":function($event){return _vm.setFileForInput($event, field)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true)}):(field.rich)?_c('vue-editor',{model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.select)?_c('v-select',{attrs:{"height":"18","outlined":"","items":_vm.state[field.items],"item-value":"id","item-text":"name","requiried":"","clearable":"","rules":field.rules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.checkbox)?_c('v-checkbox',{attrs:{"height":"18","outlined":"","requiried":"","clearable":"","rules":field.rules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}}):(field.date)?_c('div',{staticClass:"d-flex justify-space-between py-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","height":"18","outlined":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('choose_expiry_date'))}})]},proxy:true}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}})],1)],1):_c('v-text-field',{attrs:{"height":"18","outlined":"","clearable":"","hint":field.hint,"rules":field.rules},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans(field.name))}})]},proxy:true},{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}],null,true),model:{value:(_vm.defaultForm[field.model]),callback:function ($$v) {_vm.$set(_vm.defaultForm, field.model, $$v)},expression:"defaultForm[field.model]"}})],1)}),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"success","outlined":"","type":"submit"}},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('update'))}}),_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)],1)],2)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"vue-editor headline",domProps:{"innerHTML":_vm._s(_vm.trans('confirm_deleting'))}}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.close('dialogDelete')}}},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('cancel'))}}),_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm._delete}},[_c('span',{staticClass:"vue-editor",domProps:{"innerHTML":_vm._s(_vm.trans('delete'))}}),_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)],1)],1)],1)],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))])]}},{key:"item.imageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"width":"54","height":"auto","contain":"","src":item.imageUrl}})]}},{key:"item.fullImageUrl",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"width":"54","height":"auto","contain":"","src":item.fullImageUrl}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"DD-MM-YYYY"))+" ")]}},{key:"item.personalConditions",fn:function(ref){
var item = ref.item;
return [(item.personalConditions)?_c('v-icon',[_vm._v("mdi-checkbox-marked-outline")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.job_link",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-capitalize",attrs:{"href":item.job_link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.formatPositionName(item.job_link))+" ")])]}},{key:"item.files",fn:function(data){return _vm._l((data.item[data.header.modelName]),function(fileUrl,index){return _c('span',{key:index},[_c('a',{attrs:{"href":_vm.baseUrl + '/' + fileUrl,"contain":"","target":"_blank"}},[_vm._v(" Resume ")])])})}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.description ? _vm.cutContent(item.description) : '')+" ")])]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.content ? _vm.cutContent(item.content) : '')+" ")])]}},{key:"item.short_description",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.short_description ? _vm.cutContent(item.short_description) : '')+" ")])]}},{key:"item.visible",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-switch',{attrs:{"inset":"","color":"indigo darken-3","label":_vm.trans('is_allow')},on:{"change":function($event){return _vm.updateVisibleStatus(item)}},model:{value:(item.visible),callback:function ($$v) {_vm.$set(item, "visible", $$v)},expression:"item.visible"}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }