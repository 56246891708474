import ApiHomeComparingProviders from '@/api/home-comparing-providers'
import ApiHomeComparingProvidersCategory from '@/api/home-comparing-providers-category'

import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'HomeComparingProvidersTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null
		}
	},
	computed: {
		tableData () {
			let data = this.state.homeComparingProviders.map(item => {
				for (let key in item.info) {
					item[key] = item.info[key]
				}
				return item
			})
			return data
		},
		categories () {
			return this.state.homeComparingProvidersCategories
		},
		headers () {
			let headers = [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Icon url',
					sortable: false,
					value: 'imageUrl'
				}
			]
			this.categories.map(category => {
				let header = {text: this.trans(category.name), value: category.name, sortable: false}
				headers.push(header)
			})
			headers.push({ text: 'actions', value: 'actions', sortable: false })
			return headers
		},
		config () {
			let createFields = [
				{
					model: 'order',
					name: 'Order',
					rules: [
						v => !!v || 'Field is required'
					]
				},
				{
					model: 'uploadFile',
					name: 'IconUrl',
					rules: [
						v => !!v || 'Field is required',
						v => (!!v && (v.size / 1024 / 1024 ) <= 1) || 'File size over 1 MB',
					],
					file: true
				}
			]
			let updateFields = [
				{
					model: 'order',
					name: 'Order',
					rules: [
						v => !!v || 'Field is required'
					]
				},
				{
					model: 'uploadFile',
					name: 'IconUrl',
					rules: [
						v => !!v || 'Field is required',
						v => (!!v && (v.size / 1024 / 1024 ) <= 1) || 'File size over 1 MB',
					],
					file: true
				}
			]
			this.categories.map(category => {
				let field = {
					model: category.name,
					name: this.trans(category.name)
				}
				createFields.push(field)
				updateFields.push(field)
			})

			return {
				createFields,
				updateFields,
				multipart: true
			}
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiHomeComparingProviders.getAll(this)
			ApiHomeComparingProvidersCategory.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			let entries = [...data.entries()]
			for(var pair of entries) {
				if (pair[0] !== 'order' && pair[0] !== 'uploadFile') {
					data.append('info[' + pair[0] + ']', pair[1])
				}
			}
			this.form = data
			ApiHomeComparingProviders.create(this)
		},
		updateItem (data) {
			let entries = [...data.form.entries()]
			for(var pair of entries) {
				if (pair[0] !== 'order' && pair[0] !== 'uploadFile') {
					data.form.append('info[' + pair[0] + ']', pair[1])
				}
			}
			this.form = data.form
			ApiHomeComparingProviders.update(this, data.id)
		},
		deleteItem (id) {
			ApiHomeComparingProviders._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiHomeComparingProviders.getAll(this)
		ApiHomeComparingProvidersCategory.getAll(this)
	}
}
