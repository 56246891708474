import ApiAcceptCoinsRequest from '@/api/accept-coins-request'

import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'AcceptCoinsRequestsTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Full Name',
					value: 'name'
				},
				{
					text: 'Business e-mail',
					value: 'email'
				},
				{
					text: 'Phone number',
					value: 'phone'
				},
				{
					text: 'Telegram Account',
					value: 'telegram_account'
				},
				{
					text: 'Company Website',
					value: 'company_website',
					sortable: false
				},
				{
					text: 'Company register region',
					value: 'region',
					sortable: false
				},
				{
					text: 'Estimated crypto payments volume',
					value: 'crypto_payments_volume',
					sortable: false
				},
				{
					text: 'Purpose to use',
					value: 'use_purpose',
					sortable: false
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'name',
						name: 'Full Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Business e-mail',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'phone',
						name: 'Phone number',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'telegram_account',
						name: 'Telegram Account',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'company_website',
						name: 'Company Website',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'region',
						name: 'Company register region',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'crypto_payments_volume',
						name: 'Estimated crypto payments volume',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'use_purpose',
						name: 'Purpose to use',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'name',
						name: 'Full Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Business e-mail',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'phone',
						name: 'Phone number',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'telegram_account',
						name: 'Telegram Account',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'company_website',
						name: 'Company Website',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'region',
						name: 'Company register region',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'crypto_payments_volume',
						name: 'Estimated crypto payments volume',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'use_purpose',
						name: 'Purpose to use',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.acceptCoinsRequests
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiAcceptCoinsRequest.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiAcceptCoinsRequest.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiAcceptCoinsRequest.update(this, data.id)
		},
		deleteItem (id) {
			ApiAcceptCoinsRequest._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		},
		showErrors (err) {
			this.$refs.table.showErrors(err)
		}
	},
	created () {
		ApiAcceptCoinsRequest.getAll(this)
	}
}
