import ApiSubscribeUser from '@/api/subscribe-user'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'SubscribeUsersTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Email',
					value: 'email'
				},
				{
					text: 'Type',
					value: 'type'
				},
				{
					text: 'Date',
					value: 'date'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.subscribeUsers
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiSubscribeUser.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiSubscribeUser.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiSubscribeUser.update(this, data.id)
		},
		deleteItem (id) {
			ApiSubscribeUser._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		},
		showErrors (err) {
			this.$refs.table.showErrors(err)
		}
	},
	created () {
		ApiSubscribeUser.getAll(this)
	}
}
