export default [
	'home_page',
	'admin_page',
	'home_comparing_providers',
	'api_page',
	'form',
	'help_page',
	'errors',
	'about_us_page',
	'become_partner_page',
	'brand_guide_page',
	'careers_page',
	'current_position',
	'custody_solution_page',
	'footer',
	'header',
	'welcome_page',
	'premium_exchange_page',
	'wallet_page',
	'white_label_page',
	'terms_of_service_page',
	'privacy_policy_page',
	'aml_policy_page',
	'404_page',
	'blog_page',
	'contact_us_form',
	'apply_position_form',
	'become_partner_form',
	'request_access_form',
	'request_access_form',
	'under_construction_page'
]
