import ApiPartners from '@/api/partners'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'PartnersTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Image',
					value: 'imageUrl'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'uploadFile',
						name: 'Image',
						rules: [
							v => !!v || 'Field is required',
							v => (!!v && (v.size / 1024 / 1024 ) <= 1) || 'File size over 1 MB',
						],
						file: true
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'uploadFile',
						name: 'Image',
						rules: [
							v => !!v || 'Field is required',
							v => (!!v && (v.size / 1024 / 1024 ) <= 1) || 'File size over 1 MB',
						],
						file: true
					}
				],
				multipart: true
			}
		}
	},
	computed: {
		tableData () {
			return this.state.partners
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiPartners.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiPartners.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiPartners.update(this, data.id)
		},
		deleteItem (id) {
			ApiPartners._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiPartners.getAll(this)
	}
}
