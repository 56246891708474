import ApiSettings from '@/api/settings'

export default {
	name: 'SettingsTable',
	data: () => ({
		dialog: false,
		dialogDelete: false,
		form: {
			key: null,
			value: false
		},
		isCreateAllow: true,
		keyForDelete: null,
		isDeleteAllow: true
	}),
	created () {
		ApiSettings.getAll(this)
	},
	methods: {
		handleChangePemission (value, key, id) {
			ApiSettings.update(this, id, {
				key,
				value
			})
		},
		close (dialog) {
			this[dialog] = false
			this.$nextTick(() => {
				this.editIndex = -1
				this.clearForms()
			})
		},
		clearForms () {
			this.form = {}
		},
		create () {
			ApiSettings.create(this)
			this.close('dialog')
		},
		deleteItem (id) {
			this.keyForDelete = id
			this.dialogDelete = true
		},
		_delete () {
			ApiSettings._delete(this, this.keyForDelete)
			this.close('dialogDelete')
		}
	}
}
