import ApiHomeComparingProvidersCategory from '@/api/home-comparing-providers-category'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'HomeComparingProvidersCategoriesTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Name',
					value: 'name'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'name',
						name: 'Name key',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'name',
						name: 'Name key',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.homeComparingProvidersCategories
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiHomeComparingProvidersCategory.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiHomeComparingProvidersCategory.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiHomeComparingProvidersCategory.update(this, data.id)
		},
		deleteItem (id) {
			ApiHomeComparingProvidersCategory._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiHomeComparingProvidersCategory.getAll(this)
	}
}
