import ApiTeam from '@/api/team'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'TeamTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Fist name',
					value: 'first_name'
				},
				{
					text: 'Last name',
					value: 'last_name'
				},
				{
					text: 'Position',
					value: 'position'
				},
				{
					text: 'Image',
					value: 'imageUrl'
				},
				{
					text: 'LinkedIn URL',
					value: 'linked_in'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'first_name',
						name: 'First Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'last_name',
						name: 'Last Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'position',
						name: 'Position',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'linked_in',
						name: 'LinkedIn URL'
					},
					{
						model: 'uploadFile',
						name: 'Image',
						rules: [
							v => !!v || 'Field is required',
							v => (!!v && (v.size / 1024 / 1024 ) <= 1) || 'File size over 1 MB',
						],
						file: true
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'first_name',
						name: 'First Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'last_name',
						name: 'Last Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'position',
						name: 'Position',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'linked_in',
						name: 'LinkedIn URL'
					},
					{
						model: 'uploadFile',
						name: 'Image',
						rules: [
							v => !!v || 'Field is required',
							v => (!!v && (v.size / 1024 / 1024 ) <= 1) || 'File size over 1 MB',
						],
						file: true
					}
				],
				multipart: true
			}
		}
	},
	computed: {
		tableData () {
			return this.state.team
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiTeam.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiTeam.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiTeam.update(this, data.id)
		},
		deleteItem (id) {
			ApiTeam._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiTeam.getAll(this)
	}
}
