import LangsTable from '@/components/langs-table/LangsTable'
import Preloader from '@/components/preloader/Preloader.vue'
import TestimonialsTable from '@/components/testimonials-table/TestimonialsTable'
import SocialMediasTable from '@/components/social-medias-table/SocialMediasTable'
import SettingsTable from '@/components/settings-table/SettingsTable'
import FaqTable from '@/components/faq-table/FaqTable'
import NewPartnerRequestsTable from '@/components/new-partner-requests-table/NewPartnerRequestsTable'
import OpenPositionsTable from '@/components/open-positions-table/OpenPositionsTable'
import NewPositionRequestsTable from '@/components/new-position-requests-table/NewPositionRequestsTable'
import ContactSalesRequestsTable from '@/components/contact-sales-requests-table/ContactSalesRequestsTable'
import AcceptCoinsRequestsTable from '@/components/accept-coins-requests-table/AcceptCoinsRequestsTable'
import AppLangsTable from '@/components/app-langs-table/AppLangsTable'
import PostsTable from '@/components/posts-table/PostsTable.vue'
import HomeComparingProvidersCategoriesTable from '@/components/home-comparing-providers-categories-table/HomeComparingProvidersCategoriesTable'
import HomeComparingProvidersTable from '@/components/home-comparing-providers-table/HomeComparingProvidersTable'
import TeamTable from '@/components/team-table/TeamTable'
import PartnersTable from '@/components/partners-table/PartnersTable'
import HomeMedalTable from '@/components/home-medal-table/HomeMedalTable'
import EmployeeCommentsTable from '@/components/employee-comments-table/EmployeeCommentsTable'
import EmailsTable from '@/components/emails-table/EmailsTable'
import HomeSponsorTable from '@/components/home-sponsor-table/HomeSponsorTable.vue'
import SubscribeUsersTable from '@/components/subscribe-users-table/SubscribeUsersTable.vue'

import ApiBatch from '@/api/batch'

export default {
	name: 'Admin',
	components: {
		LangsTable,
		TestimonialsTable,
		SocialMediasTable,
		SettingsTable,
		FaqTable,
		Preloader,
		NewPartnerRequestsTable,
		OpenPositionsTable,
		NewPositionRequestsTable,
		ContactSalesRequestsTable,
		AcceptCoinsRequestsTable,
		AppLangsTable,
		PostsTable,
		HomeComparingProvidersCategoriesTable,
		HomeComparingProvidersTable,
		TeamTable,
		PartnersTable,
		HomeMedalTable,
		EmployeeCommentsTable,
		EmailsTable,
		HomeSponsorTable,
		SubscribeUsersTable
	},
	data: () => ({
		tab: 0
	}),
	computed: {
		currentTab () {
			return this.tabs[this.tab].title
		},
		tabs () {
			return [
				{
					title: this.trans('admin_menu_item_translations_management'),
					icon: 'mdi-translate',
					component: 'LangsTable'
				},
				{
					title: this.trans('admin_menu_item_app_languages'),
					icon: 'mdi-translate',
					component: 'AppLangsTable'
				},
				{
					title: this.trans('admin_menu_item_home_medals'),
					icon: 'mdi-shield',
					component: 'HomeMedalTable'
				},
				{
					title: this.trans('admin_menu_item_testimonials'),
					icon: 'mdi-snake',
					component: 'TestimonialsTable'
				},
				{
					title: this.trans('admin_menu_item_home_comparing_providers_categories'),
					icon: 'mdi-compare-horizontal',
					component: 'HomeComparingProvidersCategoriesTable'
				},
				{
					title: this.trans('admin_menu_item_home_comparing_providers'),
					icon: 'mdi-compare-horizontal',
					component: 'HomeComparingProvidersTable'
				},
				{
					title: this.trans('admin_menu_item_social_medias'),
					icon: 'mdi-facebook-messenger',
					component: 'SocialMediasTable'
				},
				{
					title: this.trans('admin_menu_item_settings'),
					icon: 'mdi-cog-outline',
					component: 'SettingsTable'
				},
				{
					title: this.trans('admin_menu_item_faq'),
					icon: 'mdi-account-question',
					component: 'FaqTable'
				},
				{
					title: this.trans('admin_menu_item_open_positions'),
					icon: 'mdi-snake',
					component: 'OpenPositionsTable'
				},
				{
					title: this.trans('admin_menu_blog'),
					icon: 'mdi-newspaper-variant',
					component: 'PostsTable'
				},
				{
					title: this.trans('admin_menu_team'),
					icon: 'mdi-account-details',
					component: 'TeamTable'
				},
				{
					title: this.trans('admin_menu_subscribe_users'),
					icon: 'mdi-account-details',
					component: 'SubscribeUsersTable'
				},
				{
					title: this.trans('admin_menu_partners'),
					icon: 'mdi-account-multiple',
					component: 'PartnersTable'
				},
				{
					title: this.trans('admin_menu_employee_comments'),
					icon: 'mdi-account-details',
					component: 'EmployeeCommentsTable'
				},
				{
					title: this.trans('admin_menu_employee_emails'),
					icon: 'mdi-email',
					component: 'EmailsTable'
				},
				{
					title: this.trans('admin_menu_item_home_sponsors'),
					icon: 'mdi-account-multiple',
					component: 'HomeSponsorTable'
				}
			]
		}
	},
	created() {
		ApiBatch.getAccountInfo(this)
	}
}
