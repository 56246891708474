import Translations from '@/api/translations'
import Categories from '@/assets/configs/translations-categories'
import ApiAppLang from '@/api/app-langs'
import { VueEditor } from 'vue2-editor'

export default {
	name: 'LangsTable',
	components: {
		VueEditor
	},
	props: ['isActive'],
	data: () => ({
		dialog: false,
		dialogDelete: false,
		keyForDell: '',
		editIndex: -1,
		valid: false,
		form: {category: '', key: '', langs: {}},
		collapsedItem: '',
		search: '',
		currentCategory: 'home_page',
	}),
	methods: {
		send () {
			if (!Object.keys(this.form.langs).length) {
				this.state.appLangs.forEach(lang => this.form.langs[lang.code] = '')
			}
			this.form.category = this.currentCategory
			Translations.setOne(this)
			this.close('dialog')
		},
		update (key) {
			Translations.updateOne(this, key)
			this.close('dialog')
		},
		deleteTranslation () {
			Translations.deleteOne(this, this.keyForDell)
			this.close('dialogDelete')
			this.keyForDell = ''
		},
		editItem (item) {
			this.editIndex = this.rows.indexOf(item)
			for (let i in item) {
				this.form.category = this.currentCategory
				if (i === 'key') {
					this.form.key = item[i]
				} else {
					this.form.langs[i] = item[i]
				}
			}
			this.dialog = true
		},

		deleteItem (item) {
			this.editIndex = this.rows.indexOf(item)
			this.keyForDell = item.key
			this.dialogDelete = true

		},
		close (dialog) {
			this[dialog] = false
			this.$nextTick(() => {
				this.editIndex = -1
				this.form = {
					category: '',
					key: '',
					langs: {}
				}
			})
		},
		isCollapse (e) {
			this.collapsedItem = this.collapsedItem === e ? false : e
		}
	},
	computed: {
		translations () {
			return this.state.translationsAll
		},
		headersFull () {
			if (!this.state.appLangs.length) return
			let headers = [{text: "key", value: "key"}]
			this.state.appLangs.map(lang => {
				let langHeader = {text: lang.title, value: lang.code, sortable: false}
				headers.push(langHeader)
			})
			headers.push({ text: 'actions', value: 'actions', sortable: false })
			return headers
		},
		rows () {
			const translationsAll = []
			if(this.state.translationsAll) {
				this.state.translationsAll.map(trans => {
					let transObj = {key: trans.key}
					for (let i in trans.langs) {
						transObj[i] = trans.langs[i]
					}
					translationsAll.push(transObj)
				})
				return translationsAll
			}
		},
		formTitle () {
			return this.editIndex === -1 ? 'category_new_item' : 'edit_key'
		},
		categories () {
			let list = Categories.map(item => {
				return {
					text: this.trans(item),
					value: item
				}
			})
			return list.sort((a, b) => {
				if ( a.text < b.text ) return -1
				if ( a.text > b.text ) return 1
				return 0
			})
		}
	},
	watch: {
		dialog (val) {
			val || this.close()
		},
		currentCategory (val) {
			Translations.getAllByCategory(this, val)
		},
		isActive: function (val) {
			val && Translations.getAllByCategory(this, this.currentCategory)
		}
	},
	created() {
		Translations.getAllByCategory(this, this.currentCategory)
		ApiAppLang.getAll(this)
	}
}
