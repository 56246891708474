import ApiEmails from '@/api/emails'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'TeamTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			emailFilter: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
			headers: [
				{
					text: 'Order',
					value: 'order'
				},
				{
					text: 'Destination',
					value: 'destination'
				},
				{
					text: 'Email Key',
					value: 'key'
				},
				{
					text: 'Email to:',
					value: 'email_to'
				},
				{
					text: 'Email from:',
					value: 'email_from'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'destination',
						name: 'Destination',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'key',
						name: 'Email Key',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email_to',
						name: 'Email to: ',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email_from',
						name: 'Email from: ',
						rules: [
							v => !!v || 'Field is required',
							v => this.emailFilter.test(v) || 'Email must be valid',
							v => !(/[а-яА-ЯЁё]/.test(v)) || 'Email must not be cyrillic'
						]
					}
				],
				updateFields: [
					{
						model: 'order',
						name: 'Order',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'destination',
						name: 'Destination',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'key',
						name: 'Email Key',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email_to',
						name: 'Email to: ',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email_from',
						name: 'Email from: ',
						rules: [
							v => !!v || 'Field is required',
							v => this.emailFilter.test(v) || 'Email must be valid',
							v => !(/[а-яА-ЯЁё]/.test(v)) || 'Email must not be cyrillic'
						]
					}
				],
				multipart: true
			}
		}
	},
	computed: {
		tableData () {
			return this.state.emails
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiEmails.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiEmails.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiEmails.update(this, data.id)
		},
		deleteItem (id) {
			ApiEmails._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		}
	},
	created () {
		ApiEmails.getAll(this)
	}
}
