import { VueEditor } from 'vue2-editor'

import ApiBase from "@/api/base"

export default {
	name: 'AdminTable',
	components: {
		VueEditor
	},
	props: ['headers', 'tableData', 'config', 'rowName'],
	data: () => ({
			dialog: false,
			dialogDelete: false,
			editIndex: -1,
			valid: false,
			search: '',
			baseUrl: ApiBase.baseUrl(),
			form: {},
			uploadFile: null,
			defaultForm: {},
			datePicker: false,
			errors: {}
	}),
	computed: {
		rows () {
			const itemsAll = []
			if(this.tableData) {
				this.tableData.map(field => {
					if(field.poster) {
						field.poster = `${this.baseUrl}/${field.poster}`
					}
					if(field.tags) {
						const arr = field.tags
						field.tags = arr.join(' ')
					}
					itemsAll.push(field)
				})

				return itemsAll
			}
		},
		formTitle () {
			return this.editIndex === -1
				? this.trans('admin_create_new_one')
				: `${this.trans('admin_edit')} ${this.rows[this.editIndex][(this.rowName || 'title' || 'name' || '')]}`
		}
	},
	methods: {
		create () {
			if (!this.$refs.form.validate()) return
			this.form = new FormData
			for(let field in this.defaultForm) {
				this.form.append(field, this.defaultForm[field])
			}
			this.$emit('createRequest', this.form)
		},
		hideDialog () {
			this.close('dialog')
		},
		showErrors (errors) {
			this.errors = errors
		},
		update () {
			// const row = this.rows[this.editIndex]
			this.form = new FormData
			for(let field in this.defaultForm) {
				if (this.defaultForm[field]) {
					this.form.append(field, this.defaultForm[field])
				}
			}
			this.$emit('updateRequest', { form: this.form, id: this.keyForEdit })
		},
		_delete () {
			this.$emit('deleteRequest', this.keyForDelete)
			this.close('dialogDelete')
		},
		close (dialog) {
			this[dialog] = false
			this.$nextTick(() => {
				this.editIndex = -1
				this.clearForms()
			})
		},
		clearForms () {
			this.$refs.form?.reset()
			this.defaultForm = {}
			this.form = {}
		},
		editItem (item) {
			this.editIndex = this.rows.indexOf(item)
			this.config.updateFields.map(field => {
				this.defaultForm[field.model] = item[field.model]
			})
			this.keyForEdit = item.id
			this.dialog = true
		},
		deleteItem (item) {
			this.editIndex = this.rows.indexOf(item)
			this.keyForDelete = item.id
			this.dialogDelete = true
		},
		clearError (field) {
			this.errors[field] = ''
		},
		setFileForInput (file, field) {
			this.defaultForm[field.model] = file
		},
		formatPositionName (url) {
			if (!url) return
			let urlParts = url.split('/')
			return urlParts[urlParts.length -1].split('_').join(' ')
		},
		cutContent (content) {
			if (content.length > 300) {
				return content.substring(0, 300) + '...'
			} else return content
		},
		updateVisibleStatus (item) {
			if (!item) return
			this.$emit('updateRequest', item)
		}
	}
}
