import ApiNewPositionRequest from '@/api/new-position-request'
import Table from '@/components/admin-table/AdminTable'

export default {
	name: 'NewPositionRequestsTable',
	components: {
		Table
	},
	props: ['isActive'],
	data () {
		return {
			form: null,
			headers: [
				{
					text: 'First Name',
					value: 'first_name'
				},
				{
					text: 'Last Name',
					value: 'last_name'
				},
				{
					text: 'Email',
					value: 'email'
				},
				{
					text: 'Phone',
					value: 'phone'
				},
				{
					text: 'LinkedIn URL',
					value: 'linked_in'
				},
				{
					text: 'Position',
					value: 'job_link'
				},
				{
					text: 'Resume',
					value: 'files',
					modelName: 'resume_files'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				}
			],
			config: {
				createFields: [
					{
						model: 'first_name',
						name: 'First Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'last_name',
						name: 'Last Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'phone',
						name: 'Phone',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'linked_in',
						name: 'LinkedIn URL',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				],
				updateFields: [
					{
						model: 'first_name',
						name: 'First Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'last_name',
						name: 'Last Name',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'email',
						name: 'Email',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'phone',
						name: 'Phone',
						rules: [
							v => !!v || 'Field is required'
						]
					},
					{
						model: 'linked_in',
						name: 'LinkedIn URL',
						rules: [
							v => !!v || 'Field is required'
						]
					}
				]
			}
		}
	},
	computed: {
		tableData () {
			return this.state.newPositionRequests
		}
	},
	watch: {
		isActive: function (val) {
			val && ApiNewPositionRequest.getAll(this)
		}
	},
	methods: {
		createItem (data) {
			this.form = data
			ApiNewPositionRequest.create(this)
		},
		updateItem (data) {
			this.form = data.form
			ApiNewPositionRequest.update(this, data.id)
		},
		deleteItem (id) {
			ApiNewPositionRequest._delete(this, id)
		},
		hideDialog () {
			this.$refs.table.hideDialog()
		},
		showErrors (err) {
			this.$refs.table.showErrors(err)
		}
	},
	created () {
		ApiNewPositionRequest.getAll(this)
	}
}
